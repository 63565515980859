













































































































































































































import BaseButton from '@/components/base/BaseButton.vue';
import { defineComponent, ref } from '@/plugins/composition';
import RegisterText from './RegisterText.vue';
import { rules } from '@/composables/useValidation/validations';
import { debouncedWatch } from '@vueuse/core';
import { User } from '@/models';
import { routeNames } from '@/router/routeNames';
import { useLoader } from '@/composables/useLoader';
import { useRegistrationData } from '@/composables/useRegistrationData';
import { mdiEyeOutline } from '@mdi/js';
import { useApi } from '@/composables/useApi';
import { snakeKeys } from '@/utils/case';
import { useAuthStore } from '@/composables/useAuthStore';
import { useRouter } from '@/router/useRouter';

export default defineComponent({
  components: { RegisterText, BaseButton },
  name: 'RegisterForm',
  setup() {
    const valid = ref(false);
    const isPasswordVisible = ref(false);
    const { loading, withLoader } = useLoader();
    const { artforms, orgTypes, formData, type, typeSelected, isForOrganisation, emailExists, next } =
      useRegistrationData();
    const { post } = useApi();
    const { router } = useRouter();

    const checkIfEmailExists = async () =>
      withLoader(async () => {
        emailExists.value = await User.existsByEmail(formData.email);
      });

    const register = async () => {
      withLoader(async () => {
        await post('/users', snakeKeys(formData));

        await login(formData.email, formData.password);
      });
    };

    const login = async (email: string, password: string) =>
      withLoader(async () => {
        const user = await User.login({ email, password });

        if (user) {
          const { storeLogin } = useAuthStore();

          storeLogin(user);

          router.push({ name: routeNames.profile.billing });
        }
      });

    debouncedWatch(
      () => formData.email,
      async () => checkIfEmailExists(),
      { debounce: 500, immediate: true }
    );

    return {
      type,
      isForOrganisation,
      typeSelected,
      formData,
      rules,
      valid,
      orgTypes,
      artforms,
      loading,
      emailExists,
      register,

      routeNames,
      isPasswordVisible,
      icons: {
        mdiEyeOutline,
      },
    };
  },
});
